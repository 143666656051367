import GilroyThinEOT from "./fonts/Gilroy-Thin.eot";
import GilroyThinTTF from "./fonts/Gilroy-Thin.ttf";
import GilroyThinWOFF from "./fonts/Gilroy-Thin.woff";
import GilroyThinWOFF2 from "./fonts/Gilroy-Thin.woff2";
import GilroyThinItalicEOT from "./fonts/Gilroy-Thin-Italic.eot";
import GilroyThinItalicTTF from "./fonts/Gilroy-Thin-Italic.ttf";
import GilroyThinItalicWOFF from "./fonts/Gilroy-Thin-Italic.woff";
import GilroyThinItalicWOFF2 from "./fonts/Gilroy-Thin-Italic.woff2";

import GilroyUltraLightEOT from "./fonts/Gilroy-UltraLight.eot";
import GilroyUltraLightTTF from "./fonts/Gilroy-UltraLight.ttf";
import GilroyUltraLightWOFF from "./fonts/Gilroy-UltraLight.woff";
import GilroyUltraLightWOFF2 from "./fonts/Gilroy-UltraLight.woff2";
import GilroyUltraLightItalicEOT from "./fonts/Gilroy-UltraLight-Italic.eot";
import GilroyUltraLightItalicTTF from "./fonts/Gilroy-UltraLight-Italic.ttf";
import GilroyUltraLightItalicWOFF from "./fonts/Gilroy-UltraLight-Italic.woff";
import GilroyUltraLightItalicWOFF2 from "./fonts/Gilroy-UltraLight-Italic.woff2";

import GilroyLightEOT from "./fonts/Gilroy-Light.eot";
import GilroyLightTTF from "./fonts/Gilroy-Light.ttf";
import GilroyLightWOFF from "./fonts/Gilroy-Light.woff";
import GilroyLightWOFF2 from "./fonts/Gilroy-Light.woff2";
import GilroyLightItalicEOT from "./fonts/Gilroy-Light-Italic.eot";
import GilroyLightItalicTTF from "./fonts/Gilroy-Light-Italic.ttf";
import GilroyLightItalicWOFF from "./fonts/Gilroy-Light-Italic.woff";
import GilroyLightItalicWOFF2 from "./fonts/Gilroy-Light-Italic.woff2";

import GilroyRegularEOT from "./fonts/Gilroy-Regular.eot";
import GilroyRegularTTF from "./fonts/Gilroy-Regular.ttf";
import GilroyRegularWOFF from "./fonts/Gilroy-Regular.woff";
import GilroyRegularWOFF2 from "./fonts/Gilroy-Regular.woff2";
import GilroyRegularItalicEOT from "./fonts/Gilroy-Regular-Italic.eot";
import GilroyRegularItalicTTF from "./fonts/Gilroy-Regular-Italic.ttf";
import GilroyRegularItalicWOFF from "./fonts/Gilroy-Regular-Italic.woff";
import GilroyRegularItalicWOFF2 from "./fonts/Gilroy-Regular-Italic.woff2";

import GilroyMediumEOT from "./fonts/Gilroy-Medium.eot";
import GilroyMediumTTF from "./fonts/Gilroy-Medium.ttf";
import GilroyMediumWOFF from "./fonts/Gilroy-Medium.woff";
import GilroyMediumWOFF2 from "./fonts/Gilroy-Medium.woff2";
import GilroyMediumItalicEOT from "./fonts/Gilroy-Medium-Italic.eot";
import GilroyMediumItalicTTF from "./fonts/Gilroy-Medium-Italic.ttf";
import GilroyMediumItalicWOFF from "./fonts/Gilroy-Medium-Italic.woff";
import GilroyMediumItalicWOFF2 from "./fonts/Gilroy-Medium-Italic.woff2";

import GilroySemiboldEOT from "./fonts/Gilroy-Semibold.eot";
import GilroySemiboldTTF from "./fonts/Gilroy-Semibold.ttf";
import GilroySemiboldWOFF from "./fonts/Gilroy-Semibold.woff";
import GilroySemiboldWOFF2 from "./fonts/Gilroy-Semibold.woff2";
import GilroySemiboldItalicEOT from "./fonts/Gilroy-Semibold-Italic.eot";
import GilroySemiboldItalicTTF from "./fonts/Gilroy-Semibold-Italic.ttf";
import GilroySemiboldItalicWOFF from "./fonts/Gilroy-Semibold-Italic.woff";
import GilroySemiboldItalicWOFF2 from "./fonts/Gilroy-Semibold-Italic.woff2";

import GilroyBoldEOT from "./fonts/Gilroy-Bold.eot";
import GilroyBoldTTF from "./fonts/Gilroy-Bold.ttf";
import GilroyBoldWOFF from "./fonts/Gilroy-Bold.woff";
import GilroyBoldWOFF2 from "./fonts/Gilroy-Bold.woff2";
import GilroyBoldItalicEOT from "./fonts/Gilroy-Bold-Italic.eot";
import GilroyBoldItalicTTF from "./fonts/Gilroy-Bold-Italic.ttf";
import GilroyBoldItalicWOFF from "./fonts/Gilroy-Bold-Italic.woff";
import GilroyBoldItalicWOFF2 from "./fonts/Gilroy-Bold-Italic.woff2";

import GilroyExtraBoldEOT from "./fonts/Gilroy-ExtraBold.eot";
import GilroyExtraBoldTTF from "./fonts/Gilroy-ExtraBold.ttf";
import GilroyExtraBoldWOFF from "./fonts/Gilroy-ExtraBold.woff";
import GilroyExtraBoldWOFF2 from "./fonts/Gilroy-ExtraBold.woff2";
import GilroyExtraBoldItalicEOT from "./fonts/Gilroy-ExtraBold-Italic.eot";
import GilroyExtraBoldItalicTTF from "./fonts/Gilroy-ExtraBold-Italic.ttf";
import GilroyExtraBoldItalicWOFF from "./fonts/Gilroy-ExtraBold-Italic.woff";
import GilroyExtraBoldItalicWOFF2 from "./fonts/Gilroy-ExtraBold-Italic.woff2";

import GilroyBlackEOT from "./fonts/Gilroy-Black.eot";
import GilroyBlackTTF from "./fonts/Gilroy-Black.ttf";
import GilroyBlackWOFF from "./fonts/Gilroy-Black.woff";
import GilroyBlackItalicEOT from "./fonts/Gilroy-Black-Italic.eot";
import GilroyBlackItalicTTF from "./fonts/Gilroy-Black-Italic.ttf";
import GilroyBlackItalicWOFF from "./fonts/Gilroy-Black-Italic.woff";

import GilroyHeavyEOT from "./fonts/Gilroy-Heavy.eot";
import GilroyHeavyTTF from "./fonts/Gilroy-Heavy.ttf";
import GilroyHeavyWOFF from "./fonts/Gilroy-Heavy.woff";
import GilroyHeavyWOFF2 from "./fonts/Gilroy-Heavy.woff2";
import GilroyHeavyItalicEOT from "./fonts/Gilroy-Heavy-Italic.eot";
import GilroyHeavyItalicTTF from "./fonts/Gilroy-Heavy-Italic.ttf";
import GilroyHeavyItalicWOFF from "./fonts/Gilroy-Heavy-Italic.woff";
import GilroyHeavyItalicWOFF2 from "./fonts/Gilroy-Heavy-Italic.woff2";

export const GilroyThin = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 100,
  src: `
    local(Gilroy),
    local(Gilroy-Thin),
    url(${GilroyThinEOT}),
    url(${GilroyThinEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyThinWOFF2}) format('woff2'),
    url(${GilroyThinWOFF}) format('woff'),
    url(${GilroyThinTTF})  format('truetype')
  `
};
export const GilroyThinItaliC = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 100,
  src: `
    local(Gilroy),
    local(Gilroy-Thin-Italic),
    url(${GilroyThinItalicEOT}),
    url(${GilroyThinItalicEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyThinItalicWOFF2}) format('woff2'),
    url(${GilroyThinItalicWOFF}) format('woff'),
    url(${GilroyThinItalicTTF})  format('truetype')
  `
};

export const GilroyUltraLight = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    local(Gilroy),
    local(Gilroy-UltraLight),
    url(${GilroyUltraLightEOT}),
    url(${GilroyUltraLightEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyUltraLightWOFF2}) format('woff2'),
    url(${GilroyUltraLightWOFF}) format('woff'),
    url(${GilroyUltraLightTTF})  format('truetype')
  `
};
export const GilroyUltraLightItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 200,
  src: `
    local(Gilroy),
    local(Gilroy-UltraLight-Italic),
    url(${GilroyUltraLightItalicEOT}),
    url(${GilroyUltraLightItalicEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyUltraLightItalicWOFF2}) format('woff2'),
    url(${GilroyUltraLightItalicWOFF}) format('woff'),
    url(${GilroyUltraLightItalicTTF})  format('truetype')
  `
};

export const GilroyLight = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    local(Gilroy),
    local(Gilroy-Light),
    url(${GilroyLightEOT}),
    url(${GilroyLightEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyLightWOFF2}) format('woff2'),
    url(${GilroyLightWOFF}) format('woff'),
    url(${GilroyLightTTF})  format('truetype')
  `
};
export const GilroyLightItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    local(Gilroy),
    local(Gilroy-Light-Italic),
    url(${GilroyLightItalicEOT}),
    url(${GilroyLightItalicEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyLightItalicWOFF2}) format('woff2'),
    url(${GilroyLightItalicWOFF}) format('woff'),
    url(${GilroyLightItalicTTF})  format('truetype')
  `
};

export const GilroyRegular = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local(Gilroy),
    local(Gilroy-Regular),
    url(${GilroyRegularEOT}),
    url(${GilroyRegularEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyRegularWOFF2}) format('woff2'),
    url(${GilroyRegularWOFF}) format('woff'),
    url(${GilroyRegularTTF})  format('truetype')
  `
};
export const GilroyRegularItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local(Gilroy),
    local(Gilroy-Regular-Italic),
    url(${GilroyRegularItalicEOT}),
    url(${GilroyRegularItalicEOT}?#iefix) format('embedded-opentype'),
    url(${GilroyRegularItalicWOFF2}) format('woff2'),
    url(${GilroyRegularItalicWOFF}) format('woff'),
    url(${GilroyRegularItalicTTF})  format('truetype')
  `
};

export const GilroyMedium = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
  local(Gilroy),
  local(Gilroy-Medium),
  url(${GilroyMediumEOT}),
  url(${GilroyMediumEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyMediumWOFF2}) format('woff2'),
  url(${GilroyMediumWOFF}) format('woff'),
  url(${GilroyMediumTTF})  format('truetype')
`
};
export const GilroyMediumItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 500,
  src: `
  local(Gilroy),
  local(Gilroy-Medium-Italic),
  url(${GilroyMediumItalicEOT}),
  url(${GilroyMediumItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyMediumItalicWOFF2}) format('woff2'),
  url(${GilroyMediumItalicWOFF}) format('woff'),
  url(${GilroyMediumItalicTTF})  format('truetype')
`
};

export const GilroySemibold = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
  local(Gilroy),
  local(Gilroy-Semibold),
  url(${GilroySemiboldEOT}),
  url(${GilroySemiboldEOT}?#iefix) format('embedded-opentype'),
  url(${GilroySemiboldWOFF2}) format('woff2'),
  url(${GilroySemiboldWOFF}) format('woff'),
  url(${GilroySemiboldTTF})  format('truetype')
`
};
export const GilroySemiboldItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 600,
  src: `
  local(Gilroy),
  local(Gilroy-Semibold-Italic),
  url(${GilroySemiboldItalicEOT}),
  url(${GilroySemiboldItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroySemiboldItalicWOFF2}) format('woff2'),
  url(${GilroySemiboldItalicWOFF}) format('woff'),
  url(${GilroySemiboldItalicTTF})  format('truetype')
`
};

export const GilroyBold = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
  local(Gilroy),
  local(Gilroy-Bold),
  url(${GilroyBoldEOT}),
  url(${GilroyBoldEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyBoldWOFF2}) format('woff2'),
  url(${GilroyBoldWOFF}) format('woff'),
  url(${GilroyBoldTTF})  format('truetype')
`
};
export const GilroyBoldItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
  local(Gilroy),
  local(Gilroy-Bold-Italic),
  url(${GilroyBoldItalicEOT}),
  url(${GilroyBoldItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyBoldItalicWOFF2}) format('woff2'),
  url(${GilroyBoldItalicWOFF}) format('woff'),
  url(${GilroyBoldItalicTTF})  format('truetype')
`
};

export const GilroyExtraBold = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 800,
  src: `
  local(Gilroy),
  local(Gilroy-Extrabold),
  url(${GilroyExtraBoldEOT}),
  url(${GilroyExtraBoldEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyExtraBoldWOFF2}) format('woff2'),
  url(${GilroyExtraBoldWOFF}) format('woff'),
  url(${GilroyExtraBoldTTF})  format('truetype')
`
};
export const GilroyExtraBoldItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 800,
  src: `
  local(Gilroy),
  local(Gilroy-Extrabold-Italic),
  url(${GilroyExtraBoldItalicEOT}),
  url(${GilroyExtraBoldItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyExtraBoldItalicWOFF2}) format('woff2'),
  url(${GilroyExtraBoldItalicWOFF}) format('woff'),
  url(${GilroyExtraBoldItalicTTF})  format('truetype')
`
};

export const GilroyBlack = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
  local(Gilroy),
  local(Gilroy-Black),
  url(${GilroyBlackEOT}),
  url(${GilroyBlackEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyBlackWOFF}) format('woff'),
  url(${GilroyBlackTTF})  format('truetype')
`
};
export const GilroyBlackItalic = {
  fontFamily: "Gilroy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 900,
  src: `
  local(Gilroy),
  local(Gilroy-Black-Italic),
  url(${GilroyBlackItalicEOT}),
  url(${GilroyBlackItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyBlackItalicWOFF}) format('woff'),
  url(${GilroyBlackItalicTTF})  format('truetype')
`
};

export const GilroyHeavy = {
  fontFamily: "Gilroy-Heavy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
  local(Gilroy),
  local(Gilroy-Heavy),
  url(${GilroyHeavyEOT}),
  url(${GilroyHeavyEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyHeavyWOFF2}) format('woff2'),
  url(${GilroyHeavyWOFF}) format('woff'),
  url(${GilroyHeavyTTF})  format('truetype')
`
};
export const GilroyHeavyItalic = {
  fontFamily: "Gilroy-Heavy",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
  local(Gilroy),
  local(Gilroy-Heavy-Italic),
  url(${GilroyHeavyItalicEOT}),
  url(${GilroyHeavyItalicEOT}?#iefix) format('embedded-opentype'),
  url(${GilroyHeavyItalicWOFF2}) format('woff2'),
  url(${GilroyHeavyItalicWOFF}) format('woff'),
  url(${GilroyHeavyItalicTTF})  format('truetype')
`
};
