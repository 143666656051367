import createService from "./provider";

const serviceConfig = {
  getTest: {
    url: "/test",
    method: "GET"
  },
  checkAuthentication: {
    url: "/is-authenticated",
    method: "GET"
  },
  login: {
    url: "/login",
    method: "POST",
    paramTypes: {
      values: "data"
    }
  },
  logout: {
    url: "/logout",
    method: "POST"
  },
  pwReset: {
    url: "/reset-pw",
    method: "POST",
    paramTypes: {
      values: "data"
    }
  },
  submitPwReset: {
    url: "/submit-reset-pw",
    method: "POST",
    paramTypes: {
      values: "data"
    }
  },
  getCarCategories: {
    url: "/get-car-categories",
    method: "GET"
  },
  getCars: {
    url: "/get-cars",
    method: "GET",
    paramTypes: {
      group: "query"
    }
  },
  signUp: {
    url: "/sign-up",
    method: "POST",
    paramTypes: {
      values: "data"
    }
  },
  signUpFinish: {
    url: "/finish-sign-up",
    method: "GET",
    paramTypes: {
      token: "query"
    }
  },
  getMapData: {
    url: "/get-points",
    method: "GET",
    paramTypes: {
      country: "query",
      searchText: "query"
    }
  },
  export: {
    url: "/export",
    method: "GET",
    paramTypes: {
      country: "query",
      searchText: "query"
    }
  }
};

const services = createService(serviceConfig);

export default services;
