import {
  GilroyThin,
  GilroyThinItaliC,
  GilroyLight,
  GilroyLightItalic,
  GilroyUltraLight,
  GilroyUltraLightItalic,
  GilroyRegular,
  GilroyRegularItalic,
  GilroyMedium,
  GilroyMediumItalic,
  GilroySemibold,
  GilroySemiboldItalic,
  GilroyBold,
  GilroyBoldItalic,
  GilroyExtraBold,
  GilroyExtraBoldItalic,
  GilroyHeavy,
  GilroyHeavyItalic
} from "./fonts";

const overrideTheme = theme => {
  const overrides = {
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": [
            GilroyThin,
            GilroyThinItaliC,
            GilroyLight,
            GilroyLightItalic,
            GilroyUltraLight,
            GilroyUltraLightItalic,
            GilroyRegular,
            GilroyRegularItalic,
            GilroyMedium,
            GilroyMediumItalic,
            GilroySemibold,
            GilroySemiboldItalic,
            GilroyBold,
            GilroyBoldItalic,
            GilroyExtraBold,
            GilroyExtraBoldItalic,
            GilroyHeavy,
            GilroyHeavyItalic
          ],
          body: {
            color: "#000000",
            letterSpacing: "1px"
          }
        }
      },

      MuiFormControl: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              // borderColor: 'black'
            }
          }
        }
      },

      MuiAppBar: {
        root: {
          zIndex: 0
        }
      },
      MuiCardContent: {
        root: {
          padding: "24px"
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: "10px"
        },
        elevation1: {
          boxShadow: "0px 2px 10px rgba(192, 221, 248, 0.25)"
        }
      },
      MuiTypography: {
        h1: theme.text.h1,
        h2: theme.text.h2,
        h3: theme.text.h3,
        h4: theme.text.h4,
        h5: theme.text.h5,
        subtitle1: theme.text.subtitle1,
        body1: theme.text.body,
        body2: theme.text.small
      }
    }
  };

  return { ...theme, ...overrides };
};

export default overrideTheme;
