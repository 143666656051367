import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme/theme";

const useStyles = makeStyles({
  pageFooter: {
    width: "100%",
    height: "84px",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.55)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: theme.palette.primary.gray
  }
});
const Footer = ({ t }) => {
  const classes = useStyles();
  return <div className={classes.pageFooter}>{t("footerText")}</div>;
};

Footer.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Footer);
