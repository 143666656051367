import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import CustomButton from "./Shared/CustomButton";
import { signUpFinish, setGUI } from "state/actions";
import { useGlobalDispatch } from "state/context";

const useStyles = makeStyles({
  main: {
    padding: "48px 20px",
    background: theme.palette.primary.bg,
    minHeight: "100vh"
  },
  mainContent: {
    margin: "60px 0",
    display: "table",
    position: "relative",
    borderRadius: "10px"
  },
  mainTableRow: {
    display: "table-row"
  },
  mainContentDetailedIntro: {
    width: "60%",
    height: "auto",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    boxShadow: "4px 1px 10px rgba(0, 0, 0, 0.2)",
    padding: "40px",
    color: theme.palette.primary.darkGray,
    fontSize: fontSizes.Normal,
    display: "table-cell",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  },
  mainContentDetailedIntroBenefitsTitle: {
    color: theme.palette.primary.green,
    fontSize: fontSizes.Large,
    marginBottom: "20px"
  },
  signupError: {
    color: theme.palette.primary.red
  }
});

const SignUpFinish = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const dispatch = useGlobalDispatch();

  const handleClose = () => {
    window.location.replace("/sign-in");
    setOpen(false);
  };

  const token = useMemo(() => {
    const s = history.location.search.split("=");
    return s.length > 0 ? s[1] : "";
  }, [history]);

  const [error, setError] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await signUpFinish({ token });

        dispatch(
          setGUI({
            showGlobalAlert: true,
            globalAlertText: t("labelSuccesFullRegistration")
          })
        );

        history.push("/sign-in");
      } catch (ex) {
        if (ex && ex.messages && ex.messages.length) {
          setError(t(`errors.${ex.messages[0].text}`));
        }
      }
    };
    fetchData();
  }, [dispatch, t, token]);

  if (!error) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("alertTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("labelSuccesFullRegistration")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} color="primary" label="Ok" />
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className={classes.main}>
      <div className={classes.mainContent}>
        <div className={classes.mainTableRow}>
          <div className={classes.mainContentDetailedIntro}>
            <div className={classes.mainContentDetailedIntroBenefitsTitle}>
              <Typography className={classes.signupError} variant="body1">
                {error}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpFinish.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SignUpFinish);
