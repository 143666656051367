import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CustomButton from "./Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import Background from "assets/img/map.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  main: {
    padding: "48px 20px",
    background: theme.palette.primary.bg,
    minHeight: "100vh"
  },
  intro: {
    textAlign: "center",
    fontSize: fontSizes.XXXLarge,
    color: theme.palette.primary.green
  },
  subIntro: {
    textAlign: "center",
    marginTop: "16px",
    fontSize: fontSizes.XXLarge,
    color: theme.palette.primary.green
  },
  mainContent: {
    margin: "60px 0",
    display: "table",
    position: "relative",
    borderRadius: "10px"
  },
  mainTableRow: {
    display: "table-row"
  },
  mainContentImgContainer: {
    width: "40%",
    height: "100%",
    position: "relative",
    background: `url(${Background}) center`,
    display: "table-cell",
    filter: "brightness(0.5)",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px"
  },
  mainContentImg: {
    width: "100%",
    height: "100%",
    filter: "brightness(0.5)",
    borderRadius: "10px"
  },
  mainContentImgText: {
    fontSize: fontSizes.Large,
    color: theme.palette.primary.green,
    textAlign: "center",
    position: "absolute",
    zIndex: "2",
    maxWidth: "40%",
    top: "40%",
    width: "100%"
  },
  mainContentDetailedIntro: {
    width: "60%",
    height: "auto",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    boxShadow: "4px 1px 10px rgba(0, 0, 0, 0.2)",
    padding: "40px",
    color: theme.palette.primary.darkGray,
    fontSize: fontSizes.Normal,
    display: "table-cell",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  },
  mainContentDetailedIntroBenefitsTitle: {
    color: theme.palette.primary.green,
    fontSize: fontSizes.Large,
    marginBottom: "20px"
  },
  mainContentDetailedIntroBenefit: {
    margin: "30px",
    display: "list-item",
    fontSize: fontSizes.Large
  },
  mainContentCtButton: {
    marginTop: "40px"
  },
  videoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  video: {
    marginTop: 20
  },
  "@media (max-width: 800px)": {
    btnLogin: {
      "& .MuiTypography-root": {
        fontSize: `${fontSizes.xSmall} !important`
      }
    },
    intro: {
      fontSize: fontSizes.Normal,
      lineHeight: fontSizes.Normal
    },
    subIntro: {
      fontSize: fontSizes.Small,
      lineHeight: fontSizes.Small
    },
    mainContentDetailedIntroBenefitsTitle: {
      "& .MuiTypography-root": {
        fontSize: fontSizes.Normal,
        lineHeight: fontSizes.Normal
      },
      marginBottom: "10px"
    },
    mainContentDetailedIntroBenefit: {
      margin: "10px",
      display: "list-item",
      fontSize: fontSizes.Normal
    },
    mainTableRow: {
      display: "flex",
      flexDirection: "column"
    },
    mainContentImgContainer: {
      width: "100%",
      height: "200px",
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "0px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "0px"
    },
    mainContentDetailedIntro: {
      width: "100%",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "10px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "10px"
    },
    mainContentImgText: {
      top: "10px",
      left: "0px",
      width: "100vw",
      height: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: "100%"
    }
  }
});

const Home = ({ t }) => {
  const classes = useStyles();

  const history = useHistory();

  const signUp = useCallback(() => {
    history.push("/sign-up");
  }, [history]);

  return (
    <div className={classes.main}>
      <Typography className={classes.intro} variant="h1">
        {t("intro")}
      </Typography>

      <Typography className={classes.subIntro} variant="h2">
        {t("subIntro")}
      </Typography>

      <div className={classes.mainContent}>
        <div className={classes.mainTableRow}>
          <div className={classes.mainContentImgText}>
            <Typography variant="h5">{t("imgText")}</Typography>
            <br />
            <Typography variant="h5">{t("illustration")}</Typography>
          </div>
          <div className={classes.mainContentImgContainer}>
            <div className={classes.mainContentImg}></div>
          </div>
          <div className={classes.mainContentDetailedIntro}>
            <div className={classes.mainContentDetailedIntroBenefitsTitle}>
              <Typography variant="h3">{t("benefitTitle")}</Typography>
            </div>

            <Typography
              className={classes.mainContentDetailedIntroBenefit}
              variant="body1"
            >
              {t("benefits_0")}
            </Typography>

            <Typography
              className={classes.mainContentDetailedIntroBenefit}
              variant="body1"
            >
              {t("benefits_1")}
            </Typography>

            <Typography
              className={classes.mainContentDetailedIntroBenefit}
              variant="body1"
            >
              {t("benefits_2")}
            </Typography>

            <Typography
              className={classes.mainContentDetailedIntroBenefit}
              variant="body1"
            >
              {t("benefits_3")}
            </Typography>
            <div className={classes.mainContentCtButton}>
              <CustomButton
                onClick={signUp}
                btnType="primary2"
                label={t("btnTry")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.videoContainer}>
        <iframe
          className={classes.video}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/lVOToi42CqY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

Home.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Home);
