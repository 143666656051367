import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useGlobalState, useGlobalDispatch } from "../state/context";
import { useAsync } from "react-async";
import {
  setGUI,
  getCarCategories,
  getCars,
  getMapData,
  exportData
} from "state/actions";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme/theme";
import { withTranslation } from "react-i18next";
import {
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import MyMapOpenLayers from "./map/MyMapOpenLayers";
import { debounce } from "lodash";
import CustomButton from "./Shared/CustomButton";
import FileDownload from "js-file-download";

const useStyles = makeStyles({
  main: {
    padding: "20px 20px",
    background: theme.palette.primary.lightGray,
    minHeight: "100vh"
  },
  select: {
    marginTop: 20,
    width: 300,
    "& .MuiSelect-root": {
      width: 300
    }
  },
  content: {
    width: "100%",
    height: "calc(100vh - 280px)",
    display: "flex",
    justifyContent: "space-between"
  },
  error: {
    color: theme.palette.primary.red
  },
  "@media (max-width: 800px)": {
    content: ({ model }) => ({
      height: model ? "800px" : "720px",
      flexDirection: "column"
    }),
    map: {
      minHeight: "400px"
    },
    actions: {
      margin: "0 !important"
    }
  },
  map: {
    marginTop: 20,
    width: "100%"
  },
  actions: {
    margin: 20
  },
  search: {
    marginTop: 4,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  carSection: {
    "& $select": {
      marginBottom: 20
    }
  }
});

const Main = ({ t }) => {
  const [country, setCountry] = useState("hun");
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [format, setFormat] = useState("KML");
  const [error, setError] = useState(null);

  const classes = useStyles({ model });

  const dispatch = useGlobalDispatch();
  const { cars, mapData } = useGlobalState();

  const [searchText, setSearchText] = useState(null);
  const { isLoading } = useAsync({
    promiseFn: getMapData,
    searchText,
    country: "hun",
    dispatch
  });

  const countries = useMemo(() => {
    return [{ key: "hun", lat: 47.502158, lng: 19.105713 }];
  });
  const selectedCountry = useMemo(() => {
    const c = countries.find(p => p.key === country);
    if (!c) {
      return { lat: 0, lng: 0 };
    }
    return c;
  }, [country]);

  // search after 1 second of last keystroke
  const searchHandler = useCallback(
    debounce(searchText => {
      getMapData({
        dispatch,
        country,
        searchText: encodeURIComponent(searchText)
      });
    }, 1000),
    []
  );

  const getCarsList = useCallback(
    async group => {
      await getCars({ dispatch, group });
    },
    [dispatch]
  );

  const doExport = useCallback(async () => {
    if (!model || !format) {
      setError(t("main.exportError"));
      return;
    }
    try {
      const data = await exportData({
        country,
        searchText: encodeURIComponent(searchText)
      });
      FileDownload(
        data.data,
        `evmapi_export_${new Date()
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "_")}.kml`
      );
      setError(null);
    } catch (ex) {
      dispatch(
        setGUI({
          showGlobalAlert: true,
          globalAlertText: ex.messages
            .map(msg => t(`errors.${msg.text}`))
            .join(",")
        })
      );
    }
  }, [searchText, model, format]);

  if (isLoading) return null;

  return (
    <div className={classes.main}>
      <Typography className={classes.intro} variant="body1">
        {t("main.countrySelector")}
      </Typography>
      <div className={classes.select}>
        <InputLabel id="group">{t("main.country")}</InputLabel>
        <Select
          labelId="country"
          id="country"
          name="country"
          onChange={(...params) => {
            setCountry(params[1].props.value);
            getMapData({
              dispatch,
              country: params[1].props.value,
              searchText
            });
          }}
          value={country}
        >
          <MenuItem key={0} value={0}>
            <em>{t("labelNone")}</em>
          </MenuItem>
          {countries.map(country => (
            <MenuItem key={country.key} value={country.key}>
              {t(`country.${country.key}`)}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.search}>
        <TextField
          type="searchText"
          name="searchText"
          label={t("main.searchText")}
          onChange={e => {
            setSearchText(e.target.value);
            searchHandler(e.target.value);
          }}
          value={searchText}
        />
      </div>
      <div className={classes.searchResult}>
        {t("main.searchResult", { num: mapData.length })}
      </div>
      <div className={classes.content}>
        <div className={classes.map}>
          <MyMapOpenLayers
            points={mapData}
            location={{ lat: selectedCountry.lat, lng: selectedCountry.lng }}
            searchText={searchText}
          />
        </div>
        <div className={classes.actions}>
          <Typography variant="h3">{t("main.labelExport")}</Typography>
          <div className={classes.carSection}>
            <div className={classes.select}>
              <InputLabel id="group">{t("labelCarBrand")}</InputLabel>
              <Select
                labelId="group"
                id="group"
                name="group"
                onChange={(...params) => {
                  setBrand(params[1].props.value);
                  getCarsList(params[1].props.value);
                }}
                value={brand}
              >
                <MenuItem key={null} value={null}>
                  <em>{t("labelNone")}</em>
                </MenuItem>
                <MenuItem key={"Applications"} value={"Applications"}>
                  {t("applications")}
                </MenuItem>
                <MenuItem key={"Hyundai"} value={"Hyundai"}>
                  {"Hyundai"}
                </MenuItem>
              </Select>
            </div>

            <div className={classes.select}>
              <InputLabel id="carId">{t("labelCarModel")}</InputLabel>
              <Select
                labelId="carId"
                id="carId"
                name="carId"
                onChange={(...params) => {
                  setModel(params[1].props.value);
                }}
                value={model}
              >
                <MenuItem key={null} value={null}>
                  <em>{t("labelNone")}</em>
                </MenuItem>
                {brand !== "Applications" &&
                  cars.map(car => {
                    return (
                      <MenuItem key={`car-${car.id}`} value={car.id}>
                        {car.name}
                      </MenuItem>
                    );
                  })}
                {brand === "Applications" && (
                  <MenuItem key={"google"} value={"google"}>
                    <em>{"Google maps"}</em>
                  </MenuItem>
                )}
              </Select>
              {brand && (
                <Typography variant="body">
                  {t(`main.import.${brand}`, {
                    place: t("main.labelImportPlace")
                  })}
                </Typography>
              )}
            </div>
            {model && (
              <div className={classes.select}>
                <InputLabel id="carId">{t("main.exportFormat")}</InputLabel>
                <Select
                  labelId="format"
                  id="format"
                  name="format"
                  onChange={(...params) => {
                    setFormat(params[1].props.value);
                  }}
                  value={format}
                >
                  <MenuItem key={"KML"} value={"KML"}>
                    {"KML"}
                  </MenuItem>
                  })}
                </Select>
              </div>
            )}
          </div>
          {error && (
            <Typography className={classes.error} variant="body">
              {error}
            </Typography>
          )}
          <CustomButton
            onClick={doExport}
            btnType="primary2"
            label={t("main.btnExport")}
          />
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Main);
