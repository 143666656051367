const showLoadingOverlay = () => {
  document.getElementById("loading-mask").style.display = "block";
  document.getElementById("loading-mask-icon").style.display = "block";

  return true;
};
const hideLoadingOverlay = () => {
  document.getElementById("loading-mask").style.display = "none";
  document.getElementById("loading-mask-icon").style.display = "none";
};

export { showLoadingOverlay, hideLoadingOverlay };
