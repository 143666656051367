import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const Loadingindicator = () => {
  const classes = useStyles();

  const [isPageLoadingIndicatorOn, setIsPageLoadingIndicatorOn] = useState(
    false
  );

  useEffect(() => {
    setIsPageLoadingIndicatorOn(
      window &&
        window.document.getElementById("loading-mask").style.display !== "none"
    );
  }, []);

  if (isPageLoadingIndicatorOn) {
    return <Fragment />;
  }

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};
export default Loadingindicator;
