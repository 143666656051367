import { createMuiTheme } from "@material-ui/core/styles";
import overrideTheme from "./overrides";

const fontSizes = {
  xSmall: "12px",
  Small: "16px",
  Normal: "20px",
  Large: "24px",
  XLarge: "28px",
  XXLarge: "32px",
  XXXLarge: "46px",
  XXXXLarge: "60px",
  XXXXXLarge: "68px"
};

// 100: thin
// 200: ultra light
// 300: light
// 400: regular
// 500: medium
// 600: semibold
// 700: bold
// 800: extra bold
// 900: black
// 950: heavy
const fontWeights = {
  Normal: {
    fontWeight: 400
  },
  Medium: {
    fontWeight: 500
  },
  Light: {
    fontWeight: 300
  },
  Semibold: {
    fontWeight: 600
  },
  Bold: {
    fontWeight: 700
  }
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Gilroy", "Arial", "Sans-Serif"].join(","),
    fontSizes: { ...fontSizes }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 710,
      md: 1128,
      lg: 1280,
      xl: 1920
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        subtitle1: "h2",
        paragraph: "p",
        body1: "p",
        body2: "p"
      }
    }
  },
  text: {
    h1: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.XXXXLarge,
      lineHeight: "78px",
      letterSpacing: "1px"
    },
    h2: {
      fontStyle: "normal",
      ...fontWeights.Medium,
      fontSize: "40px",
      lineHeight: "47px",
      letterSpacing: "1px"
    },
    h3: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.XXLarge,
      lineHeight: "37px",
      letterSpacing: "1px"
    },

    h4: {
      fontStyle: "normal",
      ...fontWeights.Semibold,
      fontSize: fontSizes.Large,
      lineHeight: "29px",
      letterSpacing: "1px"
    },
    h5: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.Large,
      lineHeight: "29px",
      letterSpacing: "1px"
    },
    subtitle1: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.Large,
      lineHeight: "28px",
      letterSpacing: "1px"
    },
    body: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.Normal,
      lineHeight: "28px",
      letterSpacing: "1px"
    },
    small: {
      fontStyle: "normal",
      ...fontWeights.Normal,
      fontSize: fontSizes.Small,
      lineHeight: "26px",
      letterSpacing: "1px"
    }
  },
  palette: {
    text: {
      main: "#000000"
    },
    common: {
      black: "#000000",
      white: "#FFFFFF"
    },
    primary: {
      500: "#000000",
      white: "#ffffff",
      green: "#2ddf8a",
      lightGreen: "#77e3b0",
      gray: "#afa6b7",
      bg: "#162645",
      darkGray: "#605e61",
      btn: "#0ea18f",
      red: "#ee2239",
      lightGray: "#EEEEEE",
      orange: "#ff802d",
      lightOrange: "#f0985f"
    },
    background: {
      footer: "#F8F8F8"
    }
  },
  background: {
    light: "#EFF5FC",
    lighter: "#FAFBFF",
    white: "#FFFFFF",
    gray: "#F2F2F2",
    common: "#fffefe",
    gradient1: "#F3F4F8",
    gradient2: "#F6F6FA",
    collapsible: "transparent"
  },
  common: {
    "50": "#FFFFFF"
  },
  warning: {
    "50": "#FFF7EC",
    "100": "#FFE4B6",
    "200": "#F9D18B",
    "300": "#F5AD56",
    "500": "#F29536",
    "800": "#EF752F"
  },
  error: {
    "50": "#FBF0EC",
    "100": "#F4C5B5",
    "200": "#F3A286",
    "300": "#EF764D",
    "500": "#ED4E27",
    "800": "#CD3D1F"
  },
  success: {
    "50": "#ECF9ED",
    "100": "#B6EBBC",
    "200": "#7ED188",
    "300": "#4FC25D",
    "500": "#09961A",
    "800": "#057D0F"
  },
  primary: {
    "900": "#2A45C1",
    "800": "#2E66E1",
    "700": "#3078F3",
    "600": "#3389F7",
    "500": "#3798F7",
    "400": "#4CA7F8",
    "300": "#6DB8F9",
    "200": "#98CBFA",
    "100": "#C0DDF8",
    "50": "#EFF5FC"
  }
});

export default overrideTheme(theme);
export { fontSizes, fontWeights };
