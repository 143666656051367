import React from "react";
import PropTypes from "prop-types";
import { useGlobalDispatch } from "state/context";
import { setGUI, pwReset } from "state/actions";
import { withTranslation } from "react-i18next";
import CustomButton from "components/Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      marginTop: 10
    }
  },
  error: {
    fontSize: fontSizes.Small,
    color: theme.palette.primary.red
  },
  actions: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  btnShowPw: {
    color: theme.palette.primary.darkGray,
    cursor: "pointer",
    marginTop: 8,
    "&:hover": {
      color: theme.palette.common.black
    }
  },
  title: {
    color: theme.palette.primary.bg
  },
  subTitle: {
    color: theme.palette.primary.bg,
    marginTop: 8
  },
  "@media (max-width: 800px)": {
    title: {
      fontSize: fontSizes.Small
    },
    actions: {
      "& .MuiTypography-root": {
        fontSize: fontSizes.xSmall
      }
    }
  }
});

const ForgotPwForm = ({ t }) => {
  const classes = useStyles();

  const dispatch = useGlobalDispatch();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    dispatch(setGUI({ showPwReset: false }));
    setOpen(false);
  };

  const resetPassword = async (values, setErrors) => {
    try {
      await pwReset({
        values: { ...values, emailMessage: t("emailMessage") }
      });
      setOpen(true);
    } catch (errors) {
      const { messages = [] } = errors || {};

      setErrors(
        messages.reduce((errors, error) => {
          return {
            ...errors,
            [error.reference]: t(`errors.${error.text}`)
          };
        }, {})
      );
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors }) => {
        resetPassword(values, setErrors);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("alertTitle")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("forgotAlertText")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={handleClose} color="primary" label="Ok" />
            </DialogActions>
          </Dialog>
          <Typography className={classes.title} variant="h3">
            {t("forgotTitle")}
          </Typography>
          <Typography className={classes.subTitle} variant="body1">
            {t("forgotText")}
          </Typography>
          <TextField
            type="email"
            name="email"
            label={t("labelUserName")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <div className={classes.error}>
            {errors.email && touched.email && errors.email}
          </div>
          <div className={classes.actions}>
            <CustomButton
              type="submit"
              size="small"
              disabled={isSubmitting}
              label={t("btnSendPwReset")}
            />
            <CustomButton
              size="small"
              btnType="secondary"
              onClick={() => {
                dispatch(setGUI({ showPwReset: false }));
              }}
              label={t("btnCancel")}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

ForgotPwForm.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(ForgotPwForm);
