import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useGlobalState, useGlobalDispatch } from "state/context";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import Sticky from "react-sticky-el";
import CustomButton from "components/Shared/CustomButton";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes, fontWeights } from "theme/theme";
import { setGUI, logout } from "state/actions";
import Login from "components/InputBlocks/Login";
import { ReactComponent as UserSGV } from "assets/svg/user.svg";
import { ReactComponent as LogoutSVG } from "assets/svg/logout.svg";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  pageHeader: {
    width: "100%",
    height: "84px",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
    padding: "24px",
    justifyContent: "space-between"
  },
  pageTitle: {
    cursor: "pointer",
    marginLeft: "8px",
    ...fontWeights.Normal,
    fontSize: fontSizes.Large,
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.gray
  },
  pageTitlePart1: {
    color: theme.palette.primary.green,
    fontSize: fontSizes.XXLarge
  },
  pageTitlePart2: {
    color: theme.palette.primary.gray,
    fontSize: fontSizes.XXLarge
  },
  logo: {
    position: "relative",
    top: "-2px"
  },
  loginContainer: props => ({
    transition: "all 0.5s ease-out",
    height: props.showLogin ? 300 : 0,
    overflow: "hidden"
  }),
  btnLogout: {
    width: "24px",
    cursor: "pointer"
  },
  logout: {
    display: "flex",
    alignItems: "center"
  },
  userName: {
    marginRight: 8,
    ...fontWeights.Medium
  },
  sticky: ({ showLogin }) => ({
    border: 0,
    minHeight: !showLogin ? "84px !important" : "unset !important"
  }),
  "@media (max-width: 800px)": {
    logo: {
      marginRight: 10,
      top: "1px",
      "& svg": {
        width: "40px !important"
      }
    },
    btnLogin: {
      "& .MuiTypography-root": {
        fontSize: `${fontSizes.xSmall} !important`
      }
    },
    pageTitlePart1: {
      fontSize: fontSizes.Normal
    },
    pageTitlePart2: {
      fontSize: fontSizes.Normal
    }
  }
});
const Header = ({ t }) => {
  const { GUI, user } = useGlobalState();
  const classes = useStyles({ showLogin: GUI.showLogin });

  const dispatch = useGlobalDispatch();

  const login = useCallback(() => {
    dispatch(setGUI({ showLogin: true }));
  }, [dispatch]);

  const history = useHistory();
  const doLogout = useCallback(() => {
    logout({ dispatch });
  }, [dispatch]);

  useEffect(() => {
    if (history.location.pathname === "/reset-password") {
      dispatch(setGUI({ showLogin: true, showPwResetEnd: true }));
    }
    if (history.location.pathname === "/sign-in") {
      dispatch(setGUI({ showLogin: true }));
    }
  }, [history.location.pathname, dispatch]);

  return (
    <Sticky className={classes.sticky} stickyStyle={{ zIndex: 3 }}>
      <div className={classes.pageHeader}>
        <div
          role="presentation"
          onClick={() => {
            history.push("/");
          }}
          className={classes.pageTitle}
        >
          <div className={classes.logo}>
            <Logo style={{ width: 62, height: 48 }} />
          </div>
          <Typography variant="body1">
            <span className={classes.pageTitlePart1}>EV</span>{" "}
            <span className={classes.pageTitlePart2}>mapi</span>
          </Typography>
        </div>
        {!GUI.showLogin && !user.auth && (
          <CustomButton
            onClick={login}
            size="small"
            className={classes.btnLogin}
            Icon={UserSGV}
            label={t("btnLogin")}
          />
        )}
        {user.auth && (
          <div className={classes.logout}>
            <div className={classes.userName}> {user.userName} </div>

            <LogoutSVG
              onClick={doLogout}
              className={classes.btnLogout}
              title={t("btnLogout")}
            />
          </div>
        )}
      </div>

      <div className={classes.loginContainer}>
        <Login />
      </div>
    </Sticky>
  );
};

Header.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Header);
