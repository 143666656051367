import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useGlobalDispatch, useGlobalState } from "state/context";
import { useHistory } from "react-router-dom";
import { signUp, setGUI, getCarCategories, getCars } from "state/actions";
import { withTranslation } from "react-i18next";
import CustomButton from "components/Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  InputLabel,
  Select,
  FormControl,
  MenuItem
} from "@material-ui/core";
import { useAsync } from "react-async";

const useStyles = makeStyles({
  form: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    width: "70%",
    maxWidth: "600px",
    "& .MuiFormControl-root": {
      marginTop: 10
    }
  },
  error: {
    fontSize: fontSizes.Small,
    color: theme.palette.primary.red
  },
  actions: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  btnShowPw: {
    color: theme.palette.primary.darkGray,
    cursor: "pointer",
    marginTop: 8,
    "&:hover": {
      color: theme.palette.common.black
    }
  },
  carTitle: {
    margin: "20px 0px"
  },
  carSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    "& .MuiFormControl-root": {
      width: "48%"
    }
  },
  "@media (max-width: 800px)": {
    form: {
      marginTop: 0,
      width: "100%"
    }
  }
});

const SignUpForm = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useGlobalDispatch();
  const { carCategories, cars } = useGlobalState();

  const { isLoading } = useAsync({ promiseFn: getCarCategories, dispatch });

  const doSignUp = async (values, setErrors) => {
    dispatch(setGUI({ showGoogleError: false }));
    try {
      await signUp({
        dispatch,
        values: { signUpEmailMessage: t("signUpEmailMessage"), ...values }
      });
      dispatch(
        setGUI({ showGlobalAlert: true, globalAlertText: t("signUpMessage") })
      );
      history.replace("/sign-in");
    } catch (errors) {
      const { messages = [] } = errors || {};

      setErrors(
        messages.reduce((errors, error) => {
          return {
            ...errors,
            [error.reference]: t(`errors.${error.text}`)
          };
        }, {})
      );
    }
  };

  const getCarsList = useCallback(
    async group => {
      await getCars({ dispatch, group });
    },
    [dispatch]
  );

  if (isLoading) return <React.Fragment />;

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        password: "",
        passwordAgain: "",
        group: "",
        carId: ""
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors }) => {
        doSignUp(values, setErrors);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <TextField
            type="name"
            name="name"
            label={t("labelName")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          <div className={classes.error}>
            {errors.name && touched.name && errors.name}
          </div>

          <TextField
            type="email"
            name="email"
            label={t("labelUserName")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <div className={classes.error}>
            {errors.email && touched.email && errors.email}
          </div>

          <TextField
            type="password"
            name="password"
            label={t("labelPw")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <div className={classes.error}>
            {errors.password && touched.password && errors.password}
          </div>

          <TextField
            type="password"
            name="passwordAgain"
            label={t("labelPwAgain")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordAgain}
          />
          <div className={classes.error}>
            {errors.passwordAgain &&
              touched.passwordAgain &&
              errors.passwordAgain}
          </div>

          <Typography className={classes.carTitle} variant="body1">
            {t("labelCar")}
          </Typography>

          <div className={classes.carSection}>
            <FormControl className={classes.formControl}>
              <InputLabel id="group">{t("labelCarBrand")}</InputLabel>
              <Select
                labelId="group"
                id="group"
                name="group"
                onChange={(...params) => {
                  handleChange(...params);
                  getCarsList(params[1].props.value);
                }}
                onBlur={handleBlur}
                value={values.group}
              >
                <MenuItem key={null} value={null}>
                  <em>{t("labelNone")}</em>
                </MenuItem>
                {carCategories.map(cat => {
                  return (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="carId">{t("labelCarModel")}</InputLabel>
              <Select
                labelId="carId"
                id="carId"
                name="carId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.carId}
              >
                <MenuItem key={null} value={null}>
                  <em>{t("labelNone")}</em>
                </MenuItem>
                {cars.map(car => {
                  return (
                    <MenuItem key={`car-${car.id}`} value={car.id}>
                      {car.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div className={classes.actions}>
            <CustomButton
              type="submit"
              size="small"
              btnType="primary2"
              disabled={isSubmitting}
              label={t("btnSignUp")}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

SignUpForm.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SignUpForm);
