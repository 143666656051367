import axios from "axios";
import { isArray, isObject } from "util";
import i18n from "i18n";

axios.defaults.baseURL = "/"; // it's not used, overwritten in setupBeforeEachRequestHook

export const setupBeforeEachRequestHook = () => {
  return axios.interceptors.request.use(
    config => {
      if (config.url.indexOf(`${window["jsConfig"].base_url}api`) === -1)
        config.url = `${window["jsConfig"].base_url}api${config.url}`;
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};

export const setupMessageHook = ({ dispatch, setGUI }) => {
  return axios.interceptors.response.use(
    ({ config, data, headers }) => {
      // Leber: because of CORS doesn't handle status codes for e.g. 422, I handle errors here and throw them toward
      if (typeof data === "object" && data.error) {
        throw data.error;
      }

      if (
        typeof data !== "object" &&
        config && config.url.indexOf("api/export") === -1
      ) {
        dispatch(setGUI({ showGlobalAlert: true, globalAlertText: data }));
        throw data;
      }
      return { data, headers };
    },
    error => {
      return Promise.reject(error);
    }
  );
};

const getLanguageHeaders = () => {
  //Here we can read the language settings from local storage for e.g....
  return {
    "Accept-Language": i18n.language
  };
};

/**
 * Transforms a map of parameters to a ? and & separated URI encoded query string
 * @param {*} queryParams Map of parameters and values which needs to be transformed
 */
export const encodeQueryParams = queryParams => {
  if (!queryParams) {
    return "";
  }

  const paramList = Object.keys(queryParams).reduce((acc, name) => {
    const value = queryParams[name];
    const encodedName = encodeURIComponent(name);

    if (isArray(value)) {
      return [
        ...acc,
        ...value.map(item => `${encodedName}=${encodeURIComponent(item)}`)
      ];
    }

    return [...acc, `${encodedName}=${encodeURIComponent(value)}`];
  }, []);
  return paramList.length ? `?${paramList.join("&")}` : "";
};

/**
 * Replaces parameters with values in an URL with URI encoding
 * @param {*} url The URL which containes variables in curly brace
 * @param {*} params Map of parameters and values which needs to be replaced
 */
export const replaceUrlParams = (url, params) =>
  Object.keys(params || {}).reduce((acc, key) => {
    const regexp = new RegExp(`\\{${key}\\}`, "g");
    return acc.replace(regexp, encodeURIComponent(params[key]));
  }, url);

export const processParam = (result, paramTypes, paramName, param) => {
  const {
    headerParams = {},
    urlParams = {},
    queryParams = {},
    data = {}
  } = result;
  const paramType = paramTypes[paramName] || "url";

  let newData = data;

  if (paramType === "data") {
    //inject the content of param into data
    newData = { ...data, ...param };
  } else if (paramType === "body") {
    newData = { ...data, [paramName]: param };
  } else if (
    paramType === "url" ||
    paramType === "query" ||
    paramType === "header"
  ) {
    newData = isObject(param) ? param : { [paramName]: param };
  }
  return {
    headerParams:
      paramType === "header" ? { ...headerParams, ...newData } : headerParams,
    urlParams: paramType === "url" ? { ...urlParams, ...newData } : urlParams,
    queryParams:
      paramType === "query" ? { ...queryParams, ...newData } : queryParams,
    data:
      paramType !== "url" && paramType !== "query" && paramType !== "header"
        ? newData
        : data
  };
};

const createService = config => async params => {
  const method = (config.method || "get").toLowerCase();
  const { headerParams, queryParams, data } = Object.keys(params || {}).reduce(
    (result, paramName) => ({
      ...processParam(
        result,
        config.paramTypes || {},
        paramName,
        params[paramName]
      )
    }),
    {}
  );

  const serviceConfig = {
    ...{
      ...config,
      ...{
        headers: {
          Accept: "application/json",
          ...getLanguageHeaders(),
          ...headerParams
        }
      }
    },
    method,
    url: replaceUrlParams(config.url, params) + encodeQueryParams(queryParams),
    data
  };

  return axios(serviceConfig);
};

export default (servicesCfg, baseCfg) => {
  const services = Object.keys(servicesCfg).reduce(
    (result, name) => ({
      ...result,
      [name]: createService({ ...axios(baseCfg || {}), ...servicesCfg[name] })
    }),
    {}
  );
  return services;
};
