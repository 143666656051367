import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Home from "./components/Home";
import { useAsync } from "react-async";
import { useGlobalDispatch, useGlobalState } from "./state/context";
import { setupBeforeEachRequestHook, setupMessageHook } from "./api/provider";
import Main from "./components/Main";
import PrivateRoute from "./components/Shared/PrivateRoute";
import Header from "components/Blocks/Header";
import Footer from "components/Blocks/Footer";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "theme/theme";
import { setGUI } from "state/actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import CustomButton from "components/Shared/CustomButton";
import { withTranslation } from "react-i18next";
import SignUp from "components/SignUp";
import SignUpFinish from "components/SignUpFinish";

// TODO: éles előtt kivenni!
/*const SetConfig = async () => {
  window["jsConfig"] = {
    base_url: "http://localhost/evmapi/",
    sub_dir: "/",
    email: "info@lebersoftware.hu"
  };

  return true;
};*/

const App = ({ t }) => {
  // TODO: éles előtt kivenni!
  /*const { isLoading } = useAsync({
    promiseFn: SetConfig
  });*/

  const dispatch = useGlobalDispatch();
  const {
    GUI: { showGlobalAlert, globalAlertText }
  } = useGlobalState();

  setupBeforeEachRequestHook();
  setupMessageHook({ dispatch, setGUI });

  const handleClose = () => {
    dispatch(setGUI({ showGlobalAlert: false }));
  };

  // TODO: éles előtt kivenni!
  //if (isLoading) return null;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router basename={window.jsConfig.sub_dir}>
        <Header />
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/sign-in" component={Home} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-up-finish" component={SignUpFinish} />
          <Route path="/reset-password" component={Home} />
          <PrivateRoute path="/main" component={Main} />
          <Route path="/">
            <Redirect to="/main" />
          </Route>
        </Switch>
        <Footer />
      </Router>
      <Dialog
        open={showGlobalAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("alertTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {globalAlertText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} color="primary" label="Ok" />
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(App);
