import { useContext, useMemo, useEffect } from "react";
import MapContext from "../MapContext";
import OLVectorLayer from "ol/layer/Vector";

const VectorLayer = ({ source, style, setSelectedPoint, zIndex = 0 }) => {
  const { map } = useContext(MapContext);

  let vectorLayer = useMemo(() => {
    return new OLVectorLayer({
      source,
      style
    });
  }, [source]);

  useEffect(() => {
    if (!map) return;

    map.addLayer(vectorLayer);
    vectorLayer.setZIndex(zIndex);

    map.on("pointermove", function(evt) {
      var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
        return true;
      });
      if (hit) {
        this.getTargetElement().style.cursor = "pointer";
      } else {
        this.getTargetElement().style.cursor = "";
      }
    });

    // Select a point on EVmapi, and call display on MyMapOSM component
    map.on("click", function(e) {
      var iconFeatureA = map.getFeaturesAtPixel(e.pixel);
      if (iconFeatureA !== null && iconFeatureA.length) {
        var data = iconFeatureA[0].get("data");

        let x = e.pixel[0];
        let y = e.pixel[1];

        if (x + 300 > window.document.body.offsetWidth) {
          x = x - 300;
        }

        setSelectedPoint({
          data: data,
          coords: {
            x,
            y
          }
        });
        e.preventDefault(); // avoid bubbling
      }
    });

    return () => {
      if (map) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, vectorLayer]);
  return null;
};

export default VectorLayer;
