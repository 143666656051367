import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useGlobalDispatch } from "state/context";
import { useHistory } from "react-router-dom";
import { setGUI, submitPwReset } from "state/actions";
import { withTranslation } from "react-i18next";
import CustomButton from "components/Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Formik } from "formik";
import {
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    maxWidth: "400px",
    "& .MuiFormControl-root": {
      marginTop: 10
    }
  },
  error: {
    fontSize: fontSizes.Small,
    color: theme.palette.primary.red
  },
  actions: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  btnShowPw: {
    color: theme.palette.primary.darkGray,
    cursor: "pointer",
    marginTop: 8,
    "&:hover": {
      color: theme.palette.common.black
    }
  },
  title: {
    color: theme.palette.primary.bg
  },
  "@media (max-width: 800px)": {
    title: {
      fontSize: fontSizes.Small
    },
    actions: {
      "& .MuiTypography-root": {
        fontSize: fontSizes.xSmall
      }
    },
    form: {
      width: "100%"
    }
  }
});

const ResetPwForm = ({ t }) => {
  const classes = useStyles();

  const dispatch = useGlobalDispatch();

  const history = useHistory();

  const token = useMemo(() => {
    const s = history.location.search.split("=");
    return s.length > 0 ? s[1] : "";
  }, [history]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    window.location.replace("/sign-in");
    setOpen(false);
  };

  const resetPw = async (values, setErrors) => {
    dispatch(setGUI({ showGoogleError: false }));
    try {
      await submitPwReset({ values: { ...values, token } });
      setOpen(true);
    } catch (errors) {
      const { messages = [] } = errors || {};

      setErrors(
        messages.reduce((errors, error) => {
          return {
            ...errors,
            [error.reference]: t(`errors.${error.text}`)
          };
        }, {})
      );
    }
  };

  return (
    <Formik
      initialValues={{ password: "", passwordAgain: "" }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors }) => {
        resetPw(values, setErrors);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("alertTitle")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("labelSuccessPwChange")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={handleClose} color="primary" label="Ok" />
            </DialogActions>
          </Dialog>

          <Typography className={classes.title} variant="body1">
            {t("labelAddNewPw")}
          </Typography>
          <TextField
            type="password"
            name="password"
            label={t("labelPw")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <TextField
            type="password"
            name="passwordAgain"
            label={t("labelPwAgain")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordAgain}
          />
          <div className={classes.error}>
            {errors.passwordAgain &&
              touched.passwordAgain &&
              errors.passwordAgain}
          </div>
          <div className={classes.actions}>
            <CustomButton
              type="submit"
              size="small"
              disabled={isSubmitting}
              label={t("btnReset")}
            />
            <CustomButton
              size="small"
              btnType="secondary"
              onClick={() => {
                dispatch(setGUI({ showPwResetEnd: false }));
              }}
              label={t("btnCancel")}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

ResetPwForm.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(ResetPwForm);
