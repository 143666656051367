import { constants } from "./actions";

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.SET_TEST: {
      return {
        ...state,
        test: payload
      };
    }
    case constants.SET_USER: {
      return {
        ...state,
        user: payload
      };
    }
    case constants.SET_GUI: {
      return {
        ...state,
        GUI: {
          ...state.GUI,
          ...payload
        }
      };
    }
    case constants.SET_CATEGORIES: {
      return {
        ...state,
        carCategories: [...payload]
      };
    }

    case constants.SET_CARS: {
      return {
        ...state,
        cars: [...payload]
      };
    }

    case constants.SET_MAP_DATA: {
      return {
        ...state,
        mapData: [...payload]
      };
    }

    default:
      return { ...state };
  }
};

export default reducer;
