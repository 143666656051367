import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import theme from "theme/theme";
import { makeStyles } from "@material-ui/styles";
import Map from "./Map";
import { Layers, TileLayer, VectorLayer } from "./layers";
import MarkerIcon from "assets/img/marker.png";
import { osm, vector } from "./Source";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { ReactComponent as CloseBtn } from "assets/svg/close.svg";
import { Controls, FullScreenControl, ZoomControl } from "./controls";
import { Card } from "@material-ui/core";
import { Style, Icon } from "ol/style";

const useStyles = makeStyles({
  map: {
    position: "relative",
    width: "100%",
    height: "100%",
    "& .ol-map": {
      width: "100%",
      height: "100%",
      border: 0,
      margin: 0
    }
  },
  btnClose: {
    cursor: "pointer"
  },
  infoBox: {
    backgroundColor: theme.palette.primary.white,
    opacity: 0.75,
    padding: 12
  },
  infoBoxInner: {
    fontSize: 16,
    fontColor: theme.palette.primary.darkGray,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  infoBox: {
    position: "absolute",
    backgroundColor: theme.palette.primary.white,
    opacity: 0.75,
    padding: 12,
    maxWidth: 300
  },
  infoBoxInner: {
    fontSize: 16,
    fontColor: theme.palette.primary.darkGray,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  info: {
    flex: 2
  },
  zoomControls: {
    position: "absolute",
    top: "10px",
    left: "10px"
  },
  zoomBtn: {
    margin: "4px",
    background: "white",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "@media (max-width: 800px)": {
    zoomBtn: {
      width: "60px",
      height: "60px"
    }
  }
});

const MyMapOpenLayers = props => {
  const { location, points, searchText } = props;
  const classes = useStyles();

  const [center, setCenter] = useState([
    parseFloat(location.lng),
    parseFloat(location.lat)
  ]);
  const [features, setFeatures] = useState([]);

  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    //Jump to first search result if exists
    if (searchText && points.length > 0) {
      setCenter([parseFloat(points[0].lng), parseFloat(points[0].lat)]);
    } else {
      setCenter([parseFloat(location.lng), parseFloat(location.lat)]);
    }
  }, [location, searchText, points]);

  useEffect(() => {
    const markerStyle = new Style({
      image: new Icon({
        anchor: [0.2, 0.2],
        scale: [0.8, 0.8],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        cursor: "pointer",
        src: MarkerIcon
      })
    });

    const pointsWithIcon = points.map(point => {
      const feature = new Feature({
        geometry: new Point(
          fromLonLat([parseFloat(point.lng), parseFloat(point.lat)])
        ),
        data: {
          ...point
        }
      });
      feature.setStyle(markerStyle);
      return feature;
    });

    setFeatures(pointsWithIcon);
  }, [points]);

  const [selectedPoint, setSelectedPoint] = useState(null);

  const setSelectedPointAndView = data => {
    setCenter([parseFloat(data.data.lng), parseFloat(data.data.lat)]);
    setSelectedPoint(data);
  };

  return (
    <div className={classes.map}>
      <Map center={fromLonLat(center)} zoom={zoom}>
        <Layers>
          <TileLayer source={osm()} zIndex={0} />
          <VectorLayer
            setSelectedPoint={setSelectedPointAndView}
            source={vector({
              features
            })}
          />
        </Layers>
        <Controls>
          <FullScreenControl />
        </Controls>
      </Map>
      <div className={classes.zoomControls}>
        <div
          className={classes.zoomBtn}
          onClick={() => {
            setZoom(prev => prev + 0.5);
          }}
        >
          +
        </div>
        <div
          className={classes.zoomBtn}
          onClick={() => {
            setZoom(prev => prev - 0.5);
          }}
        >
          -
        </div>
      </div>
      {selectedPoint && (
        <Card
          className={classes.infoBox}
          style={{
            left: "calc(50% + 35px)",
            top: "calc(50% + 45px)"
          }}
        >
          <div className={classes.infoBoxInner}>
            <div className={classes.info}>
              {selectedPoint.data.name} - {selectedPoint.data.address}
            </div>
            <CloseBtn
              onClick={() => {
                setSelectedPoint(null);
              }}
              className={classes.btnClose}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

MyMapOpenLayers.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    })
  )
};

export default MyMapOpenLayers;
