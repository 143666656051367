import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";
import reducer from "./reducer";

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw Error("useGlobalState must be used inside GlobalProvider!");
  }
  return context;
};

const useGlobalDispatch = () => {
  const context = useContext(GlobalDispatchContext);
  if (!context) {
    throw Error("useGlobalDispatch must be used inside GlobalProvider!");
  }
  return context;
};

const initialState = {
  test: [],
  GUI: {
    showLogin: false,
    showPwReset: false,
    showGoogleError: false,
    showGlobalAlert: false,
    showPwResetEnd: false,
    globalAlertText: ""
  },
  user: {
    auth: false,
    userName: ""
  },
  carCategories: [],
  cars: [],
  mapData: []
};

const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { useGlobalState, useGlobalDispatch, GlobalProvider };
