import React, { useCallback } from "react";
import { Route, useHistory } from "react-router-dom";
import { useAsync } from "react-async";
import { checkAuthentication } from "../../state/actions";
import { useGlobalDispatch, useGlobalState } from "../../state/context";

export const PrivateRoute = ({ children, ...rest }) => {
  const dispatch = useGlobalDispatch();
  const history = useHistory();
  const { user } = useGlobalState();

  const { isLoading } = useAsync({ promiseFn: checkAuthentication, dispatch });

  const redirect = useCallback(() => {
    history.replace("/home");
  }, [history]);

  if (isLoading) return null;

  if (!user || user.auth === false) redirect();

  return <Route {...rest} render={() => children} />;
};

export default PrivateRoute;
