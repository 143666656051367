import services from "../api/services";
import {
  showLoadingOverlay,
  hideLoadingOverlay
} from "../components/Shared/LoadingOverlay";

export const constants = {
  SET_TEST: "SET_TEST",
  SET_USER: "SET_USER",
  SET_GUI: "SET_GUI",
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_CARS: "SET_CARS",
  SET_MAP_DATA: "SET_MAP_DATA"
};

const setTest = payload => {
  return {
    payload,
    type: constants.SET_TEST
  };
};

const setUser = payload => {
  return {
    payload,
    type: constants.SET_USER
  };
};

export const setGUI = payload => {
  return {
    payload,
    type: constants.SET_GUI
  };
};

export const setCategories = payload => {
  return {
    payload,
    type: constants.SET_CATEGORIES
  };
};

export const setCars = payload => {
  return {
    payload,
    type: constants.SET_CARS
  };
};

const setMapData = payload => {
  return {
    payload,
    type: constants.SET_MAP_DATA
  };
};

export const getTest = async ({ dispatch }) => {
  showLoadingOverlay();
  try {
    const res = await services.getTest();
    const { data } = res;
    dispatch(setTest(data));
    return data;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const checkAuthentication = async ({ dispatch }) => {
  showLoadingOverlay();
  try {
    const res = await services.checkAuthentication();
    const { data } = res;
    dispatch(setUser(data));
    return data;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const login = async ({ values, dispatch }) => {
  showLoadingOverlay();
  try {
    const res = await services.login({ values });
    const { data } = res;
    dispatch(setUser(data));
    return data;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const logout = async ({ dispatch }) => {
  showLoadingOverlay();
  try {
    await services.logout();
    dispatch(
      setUser({
        auth: false,
        userName: ""
      })
    );
    return null;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const signUp = async ({ values }) => {
  showLoadingOverlay();
  try {
    return await services.signUp({ values });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const signUpFinish = async ({ token }) => {
  showLoadingOverlay();
  try {
    return await services.signUpFinish({ token });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const pwReset = async ({ values }) => {
  showLoadingOverlay();
  try {
    return await services.pwReset({ values });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const submitPwReset = async ({ values }) => {
  showLoadingOverlay();
  try {
    return await services.submitPwReset({ values });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getCarCategories = async ({ dispatch }) => {
  showLoadingOverlay();
  try {
    const categories = await services.getCarCategories();
    dispatch(setCategories(categories.data));
    return categories;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getCars = async ({ dispatch, group }) => {
  showLoadingOverlay();
  try {
    const cars = await services.getCars({ group });
    dispatch(setCars(cars.data));
    return cars;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getMapData = async ({ dispatch, country, searchText }) => {
  showLoadingOverlay();
  try {
    const points = await services.getMapData({ country, searchText });
    dispatch(setMapData(points.data));
    return points;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const exportData = async ({ country, searchText }) => {
  showLoadingOverlay();
  try {
    return await services.export({ country, searchText });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};
