import React, { useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import CustomButton from "components/Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme/theme";
import LoginForm from "./LoginForm";
import GoogleLoginForm from "./GoogleLoginForm";
import { Typography } from "@material-ui/core";
import ForgotPwForm from "./ForgotPwForm";
import { useGlobalState, useGlobalDispatch } from "state/context";
import ResetPwForm from "./ResetPwForm";
import { setGUI } from "state/actions";

const useStyles = makeStyles({
  root: {
    background: theme.palette.common.white,
    height: "100%",
    padding: "10px 40px",
    display: "flex"
  },
  section: {
    borderLeft: `2px solid ${theme.palette.primary.lightGray}`,
    marginLeft: 20,
    paddingLeft: 20
  },
  labelSignUp: {
    color: theme.palette.primary.bg
  },
  btnSignUp: {
    marginTop: 20
  },
  "@media (max-width: 800px)": {
    root: {
      flexDirection: "column",
      overflowY: "scroll",
      width: "100%",
      padding: "5px 10px"
    },
    section: {
      width: "100%",
      borderTop: `2px solid ${theme.palette.primary.lightGray}`,
      marginBottom: 20,
      borderLeft: "0",
      paddingTop: 20,
      paddingLeft: 0,
      marginLeft: 0
    }
  }
});

const Login = ({ t }) => {
  const classes = useStyles();
  const {
    GUI: { showPwReset, showPwResetEnd }
  } = useGlobalState();
  const history = useHistory();
  const dispatch = useGlobalDispatch();

  const signUp = useCallback(() => {
    history.push("/sign-up");
    dispatch(setGUI({ showLogin: false }));
  }, [history]);

  return (
    <div className={classes.root}>
      {!showPwReset && !showPwResetEnd && <LoginForm />}
      {showPwReset && <ForgotPwForm />}
      {showPwResetEnd && <ResetPwForm />}
      {!showPwReset && !showPwResetEnd && (
        <React.Fragment>
          <div className={classes.section}>
            <GoogleLoginForm />
          </div>

          <div className={classes.section}>
            <Typography className={classes.labelSignUp} variant="h2">
              {t("labelSignUpAcc")}
            </Typography>
            <CustomButton
              className={classes.btnSignUp}
              onClick={signUp}
              btnType="primary2"
              size="small"
              label={t("btnTry")}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Login);
