import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useGlobalDispatch, useGlobalState } from "state/context";
import { useHistory } from "react-router-dom";
import { login, setGUI } from "state/actions";
import { withTranslation } from "react-i18next";
import CustomButton from "components/Shared/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  form: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    maxWidth: "400px",
    "& .MuiFormControl-root": {
      marginTop: 10
    }
  },
  error: {
    fontSize: fontSizes.Small,
    color: theme.palette.primary.red
  },
  actions: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between"
  },
  btnShowPw: {
    color: theme.palette.primary.darkGray,
    cursor: "pointer",
    marginTop: 8,
    "&:hover": {
      color: theme.palette.common.black
    }
  },
  "@media (max-width: 800px)": {
    actions: {},
    form: {
      width: "100%",
      marginBottom: 20
    }
  }
});

const LoginForm = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const { GUI } = useGlobalState();

  const dispatch = useGlobalDispatch();

  const fInput = useRef(null);

  useEffect(() => {
    console.log(fInput);
    if (GUI.showLogin && fInput && fInput.current) {
      fInput.current.getElementsByTagName("input")[0].focus();
    }
  }, [fInput, GUI]);

  const doLogin = async (values, setErrors) => {
    dispatch(setGUI({ showGoogleError: false }));
    try {
      await login({ dispatch, values });
      dispatch(setGUI({ showLogin: false }));
      history.push("/main");
    } catch (errors) {
      const { messages = [] } = errors || {};

      setErrors(
        messages.reduce((errors, error) => {
          return {
            ...errors,
            [error.reference]: t(`errors.${error.text}`)
          };
        }, {})
      );
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setErrors }) => {
        doLogin(values, setErrors);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
        /* and other goodies */
      }) => (
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <TextField
            ref={fInput}
            type="email"
            name="email"
            label={t("labelUserName")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <div className={classes.error}>
            {errors.email && touched.email && errors.email}
          </div>
          <TextField
            type="password"
            name="password"
            label={t("labelPw")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <div className={classes.error}>
            {errors.password && touched.password && errors.password}
          </div>
          <div className={classes.actions}>
            <CustomButton
              type="submit"
              size="small"
              disabled={isSubmitting}
              label={t("btnLogin")}
            />
            <CustomButton
              size="small"
              btnType="secondary"
              onClick={() => {
                dispatch(setGUI({ showLogin: false }));
              }}
              label={t("btnCancel")}
            />
          </div>
          <div
            role="presentation"
            onClick={() => {
              dispatch(setGUI({ showPwReset: true }));
            }}
            className={classes.btnShowPw}
          >
            {t("labelForgotPassword")}
          </div>
        </form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(LoginForm);
