import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Typography } from "@material-ui/core";
import SignUpForm from "./InputBlocks/SignUpForm";

const useStyles = makeStyles({
  main: {
    padding: "48px 20px",
    background: theme.palette.primary.bg,
    minHeight: "100vh"
  },
  mainContent: {
    margin: "60px 0",
    display: "table",
    position: "relative",
    borderRadius: "10px"
  },
  mainTableRow: {
    display: "table-row"
  },
  mainContentDetailedIntro: {
    width: "60%",
    height: "auto",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    boxShadow: "4px 1px 10px rgba(0, 0, 0, 0.2)",
    padding: "40px",
    color: theme.palette.primary.darkGray,
    fontSize: fontSizes.Normal,
    display: "table-cell",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px"
  },
  mainContentDetailedIntroBenefitsTitle: {
    color: theme.palette.primary.green,
    fontSize: fontSizes.Large,
    marginBottom: "20px"
  },
  "@media (max-width: 800px)": {
    mainContentDetailedIntroBenefitsTitle: {
      "& .MuiTypography-root": {
        fontSize: fontSizes.Small,
        lineHeight: fontSizes.Small
      },
      marginBottom: "10px"
    }
  }
});

const SignUp = ({ t }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.mainContent}>
        <div className={classes.mainTableRow}>
          <div className={classes.mainContentDetailedIntro}>
            <div className={classes.mainContentDetailedIntroBenefitsTitle}>
              <Typography variant="h3">{t("signUpTitle")}</Typography>
              <SignUpForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SignUp);
