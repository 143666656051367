import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  customButton: ({ btnType }) => ({
    background:
      btnType === "primary"
        ? theme.palette.primary.btn
        : btnType === "primary2"
        ? theme.palette.primary.orange
        : theme.palette.common.white,
    color:
      btnType === "secondary"
        ? theme.palette.primary.bg
        : theme.palette.common.white,
    borderRadius: 6,
    cursor: "pointer",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border:
      btnType === "secondary"
        ? `1px solid ${theme.palette.primary.bg}`
        : "none",
    "&:hover": {
      background:
        btnType === "primary"
          ? theme.palette.primary.lightGreen
          : btnType === "primary2"
          ? theme.palette.primary.lightOrange
          : theme.palette.common.white
    },
    "&.large": {
      fontSize: fontSizes.Large,
      padding: "16px"
    },
    "&.normal": {
      fontSize: fontSizes.Normal,
      padding: "14px"
    },
    "&.small": {
      fontSize: fontSizes.Small,
      padding: "8px"
    }
  }),
  loginIcon: {
    position: "relative",
    top: "1px",
    left: "-2px",
    "& path": {
      fill: theme.palette.common.white
    }
  }
});

const CustomButton = ({
  label,
  size,
  type,
  btnType,
  Icon,
  onClick,
  className,
  ...rest
}) => {
  const classes = useStyles({ btnType });

  return (
    <button
      {...rest}
      type={type}
      className={`${className} ${classes.customButton} ${size}`}
      onClick={onClick}
    >
      {Icon && (
        <div className="icon">
          <Icon className={classes.loginIcon} />
        </div>
      )}
      <Typography variant="body1">{label}</Typography>
    </button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  btnType: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
};

CustomButton.defaultProps = {
  className: "",
  Icon: null,
  btnType: "primary",
  type: "button",
  onClick: undefined,
  size: "large" /*large, normal, small */
};

export default CustomButton;
