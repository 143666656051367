import React from "react";
import PropTypes from "prop-types";
import { useGlobalDispatch, useGlobalState } from "state/context";
import { useHistory } from "react-router-dom";
import { login, setGUI } from "state/actions";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontSizes } from "theme/theme";
import { GoogleLogin } from "react-google-login";

const useStyles = makeStyles({
  root: {
    background: theme.palette.common.white,
    height: "100%",
    padding: "40px",
    display: "flex"
  },
  section: {
    borderLeft: `2px solid ${theme.palette.primary.lightGray}`,
    marginLeft: 20,
    paddingLeft: 20
  },
  labelSignUp: {
    color: theme.palette.primary.bg
  },
  btnSignUp: {
    marginTop: 20
  },
  error: {
    fontSize: fontSizes.Small,
    color: theme.palette.primary.red
  }
});

const Login = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    GUI: { showGoogleError }
  } = useGlobalState();
  const dispatch = useGlobalDispatch();

  const doLogin = async values => {
    try {
      await login({ dispatch, values });
      dispatch(setGUI({ showLogin: false }));
      history.push("/main");
    } catch (errors) {
      dispatch(setGUI({ showGoogleError: true }));
    }
  };

  const responseGoogle = async response => {
    dispatch(setGUI({ showGoogleError: false }));
    if (response && response.profileObj)
      doLogin({
        googleToken: response.tokenId,
        email: response.profileObj.email
      });
  };

  return (
    <React.Fragment>
      <GoogleLogin
        clientId="768081750160-hka4rr3q78tprgp9hjqcpqubrhka25e0.apps.googleusercontent.com"
        buttonText={t("btnLoginGoogle")}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
      {showGoogleError && (
        <div className={classes.error}>{t("errors.GOOGLE_INVALID_USER")}</div>
      )}
    </React.Fragment>
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Login);
